<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-09 10:29:29
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-09 10:59:37
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/CostManager/CostSum/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="cost-sum-container">
    <div class="sum-data">
      <span>账号总金额：{{ moneyType }}{{ numFormat(aggregateAmount, 3, '') }}</span>
      <span>账号已消耗金额：{{ moneyType }}{{ numFormat(consumeAmount, 3, '') }}</span>
      <span>账号可用金额：{{ moneyType }}{{ numFormat(residueAmount, 3, '') }}</span>
    </div>
    <a-button type="link" @click="rechargeVisible = true">计费标准</a-button>
    <RechargeModal :visible="rechargeVisible" @modalCancel="rechargeVisible = false"/>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
import { getCapitalSumData } from '@/apiForManage/finance/capital'
import { mapState } from 'vuex'
import RechargeModal from './RechargeModal'
export default {
  name: 'CostSum',
  components: { RechargeModal },
  data () {
    return {
      rechargeVisible: false,
      aggregateAmount: 0,
      consumeAmount: 0,
      residueAmount: 0
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥'),
      currentLoginId: (state) => state.user.id
    })
  },
  methods: {
    numFormat,
    handleSearch () {
      this.getCapitalSumData()
    },
    async getCapitalSumData () {
     const { aggregateAmount = 0, consumeAmount = 0, residueAmount = 0 } = await getCapitalSumData()
     this.aggregateAmount = aggregateAmount
     this.consumeAmount = consumeAmount
     this.residueAmount = residueAmount
    },
    handleSkipToRecharge () {
      this.$router.push({ name: 'capital' })
    }
  }
}
</script>

<style lang="less" scoped>
.cost-sum-container {
  border-radius: @mediumRadius;
  box-shadow: @modelShadow;
  border: 1px solid @modelBorderColor;
  background-color: #fff;
  padding: @smallMargin;
  margin-bottom: @mediumMargin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sum-data{
    display: flex;
    align-items: center;
    span{
      &:not(:last-child)::after{
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: @modelBorderColor;
        margin: 0 @smallMargin;
      }
    }
  }
}
</style>
